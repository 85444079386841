// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
}

html,
body,
div#root {
  height: 100vh;
}
div#root {
  display: flex;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-grow-shrink {
  flex-grow: 1;
  flex-shrink: 1;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;
  outline: none;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;

  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,cAAc;EACd,aAAa;AACf;;AAEA;;;EAGE,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;EACT,iCAAiC;EACjC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,WAAW;EACX,iBAAiB;;EAEjB,uBAAuB;EACvB,aAAa;;EAEb,uCAAuC;EACvC,cAAc;EACd,aAAa;EACb,mBAAmB;;EAEnB,4EAA4E;EAC5E,mBAAmB;;EAEnB,uCAAuC;EACvC,+BAA+B;EAC/B,gCAAgC;;EAEhC,+DAA+D;EAC/D,wBAAwB;AAC1B;;AAEA,mDAAmD;AACnD;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["* {\n  box-sizing: border-box;\n  flex-grow: 0;\n  flex-shrink: 0;\n  outline: none;\n}\n\nhtml,\nbody,\ndiv#root {\n  height: 100vh;\n}\ndiv#root {\n  display: flex;\n}\n\nbody {\n  margin: 0;\n  font-family: \"Roboto\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.flex-grow-shrink {\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n\nbutton {\n  border: none;\n  margin: 0;\n  padding: 0;\n  width: auto;\n  overflow: visible;\n\n  background: transparent;\n  outline: none;\n\n  /* inherit font & color from ancestor */\n  color: inherit;\n  font: inherit;\n  text-align: inherit;\n\n  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */\n  line-height: normal;\n\n  /* Corrects font smoothing for webkit */\n  -webkit-font-smoothing: inherit;\n  -moz-osx-font-smoothing: inherit;\n\n  /* Corrects inability to style clickable `input` types in iOS */\n  -webkit-appearance: none;\n}\n\n/* Remove excess padding and border in Firefox 4+ */\nbutton::-moz-focus-inner {\n  border: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
