import React, { useState, useEffect } from 'react'
import { matchPath } from 'react-router'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import store, { registerReducer } from './store'
import AsyncComponent from '@base_ui/components/AsyncComponent'
import Nav from '@base_ui/components/Nav'
import classnames from 'classnames'
import { Authorize } from './components/auth/AuthorizeGates'

import './gestalt.scss'
import './App.scss'

window.store = store

const ui_modules = process.env.UI_MODULES.split(',').map(ui_mod =>
  ui_mod.replace(/^modules\//, '').trim()
)
const ui_modules_views = []
const ui_modules_reducers = []
let ui_module_branding = null

ui_modules.forEach(ui_mod => {
  let mod_path = null
  const match = ui_mod.match(/^([^\/]+\/)?(views|branding|reducers)\/(.*)$/)
  if (match) {
    let [_, pkg_prefix, mod_type, _path] = match
    if (pkg_prefix === 'framework/') {
      // Local modules, don't change path
      mod_path = `./${mod_type}/${_path}`
    } else {
      if (!pkg_prefix) {
        pkg_prefix = 'project/'
      }
      mod_path = `@gestalt/${pkg_prefix}src/${mod_type}/${_path}`
    }
    if (mod_type === 'views') {
      ui_modules_views.push(`${mod_path}`)
    } else if (mod_type === 'reducers') {
      ui_modules_reducers.push(`${mod_path}`)
    } else if (mod_type === 'branding') {
      ui_module_branding = mod_path
    }
  }
})
// console.log(ui_modules_views, ui_modules_reducers, ui_module_branding)
// const ui_modules_views = ui_modules
//   .filter(mod_path => mod_path.indexOf('views/') !== -1)
//   .map(transform_nonlocal_imports_path)
// console.log(ui_modules_views)
// const ui_modules_reducers = ui_modules
//   .filter(mod_path => mod_path.indexOf('reducers/') !== -1)
//   .map(transform_nonlocal_imports_path)
// const ui_module_branding = transform_nonlocal_imports_path(
//   ui_modules.find(mod_path => mod_path.indexOf('branding/') !== -1) || null
// )

async function import_mod_from_project_structure (mod_str) {
  let mod
  // We have many ifs and imports with explicit prefixes (like @gestalt/project/src) for webpack to properly
  // list which modules are importable
  if (mod_str.startsWith('.')) {
    if (mod_str.startsWith('./views/')) {
      mod = {
        ...(await import(
          `./views/${mod_str.replace('./views/', '').split('?')[0]}`
        ))
      }
    } else if (mod_str.startsWith('./branding/')) {
      mod = {
        ...(await import(
          `./branding/${mod_str.replace('./branding/', '').split('?')[0]}`
        ))
      }
    } else if (mod_str.startsWith('./reducers/')) {
      mod = {
        ...(await import(
          `./reducers/${mod_str.replace('./reducers/', '').split('?')[0]}`
        ))
      }
    } else {
      throw Error(`Unknown module prefix: ${mod_str}`)
    }
  } else if (mod_str.indexOf('project/') !== -1) {
    mod = {
      ...(await import(
        `@gestalt/project/src/${
          mod_str.replace('@gestalt/project/src/', '').split('?')[0]
        }`
      ))
    }
  } else if (mod_str.indexOf('common/') !== -1) {
    mod = {
      ...(await import(
        `@gestalt/common/src/${
          mod_str.replace('@gestalt/common/src/', '').split('?')[0]
        }`
      ))
    }
  } else {
    throw Error(`Unknown module location: ${mod_str}`)
  }
  return mod
}

// https://medium.com/front-end-weekly/lazy-loading-with-react-and-webpack-2-8e9e586cf442
// https://medium.com/front-end-weekly/lazy-loading-with-react-redux-and-webpack-2-35ad6fc1b640
// const view_modules = ui_modules_views.map(mod_str => ({mod: import(`./${str.split('?')[0]}`), mod_path: str.split('?')[0], query_string: str.split('?')[1]}))
const view_modules = ui_modules_views.map(async mod_str => {
  // Make a copy to convert a read-only object to a real object
  const mod = await import_mod_from_project_structure(mod_str)
  try {
    if (mod_str.indexOf('?') !== -1) {
      const query_string = new URLSearchParams(mod_str.split('?')[1])
      mod.extraprops = {}
      for (const param of query_string.keys()) {
        if (
          ['route', 'linkTitle', 'linkIcon', 'route_exact'].indexOf(param) !==
          -1
        ) {
          // The param is checked above
          // eslint-disable-next-line security/detect-object-injection
          mod[param] = query_string.get(param)
        } else if (
          ['required_scopes', 'allowed_user_ids'].indexOf(param) !== -1
        ) {
          // The param is checked above
          // eslint-disable-next-line security/detect-object-injection
          mod[param] = query_string.get(param).split(' ')
        } else {
          // The querystring and thus params comes from an env var, which is baked during the UI build process
          // eslint-disable-next-line security/detect-object-injection
          mod.extraprops[param] = query_string.get(param)
        }
      }
    }
  } catch (err) {
    console.log(err)
    throw err
  }
  return mod
})
const reducer_modules = ui_modules_reducers.map(mod_path =>
  import_mod_from_project_structure(`${mod_path}`)
)
const branding_module = ui_module_branding
  ? () => import_mod_from_project_structure(`${ui_module_branding}`)
  : null

export function ViewNotFoundIndicator () {
  return <div>View not found...</div>
}

function log_promise_errors (modules) {
  modules
    .filter(mod => mod.status === 'rejected')
    .map(mod => console.error(mod.reason))
  return modules
}

function useRouteMatchingModule (modules) {
  const location = useLocation()
  if (!modules) {
    return null
  }
  const matching_mod = modules.find(mod =>
    matchPath(location.pathname, mod.route)
  )
  return matching_mod
}

function AppContent (props) {
  // console.log(process.env)
  const [modules, setModules] = useState(null)
  const [reducers, setReducers] = useState(null)
  const route_matching_module = useRouteMatchingModule(modules)

  useEffect(() => {
    Promise.allSettled(view_modules)
      .then(log_promise_errors)
      .then(modules => modules.filter(mod => mod.status === 'fulfilled'))
      .then(modules => modules.map(mod => mod.value))
      .then(setModules)

    Promise.allSettled(reducer_modules)
      .then(log_promise_errors)
      .then(modules => modules.filter(mod => mod.status === 'fulfilled'))
      .then(modules => modules.map(mod => mod.value))
      .then(modules => {
        for (const mod of modules) {
          registerReducer(store, mod.name, mod.reducer)
          if (mod.onCreate) {
            mod.onCreate(store)
          }
        }
        setReducers(modules)
      })
  }, [])
  if (modules === null) {
    return process.env.UI_SHOW_ASYNC_LOADING_TEXT !== '0' ? (
      <div>Loading modules</div>
    ) : null
  } else if (reducers === null) {
    return process.env.UI_SHOW_ASYNC_LOADING_TEXT !== '0' ? (
      <div>Loading reducers</div>
    ) : null
  }
  const view_routes = modules.map(mod => {
    const Component = Object.prototype.hasOwnProperty.call(mod, 'View')
      ? mod.View
      : mod.Component
    return (
      <Route
        exact={!!mod.route_exact}
        path={mod.route}
        key={mod.route}
        component={() => (
          <Authorize
            required_scopes={mod.required_scopes}
            allowed_user_ids={mod.allowed_user_ids}
            error_component={() => <Redirect to='/login' />}
          >
            <Component {...props} route={mod.route} {...mod.extraprops} />
          </Authorize>
        )}
      />
    )
  })

  const default_route =
    process.env.UI_DEFAULT_ROUTE === '/' ? (
      <Route exact path='/' component={() => <div />} />
    ) : (
      <Route
        exact
        path='/'
        component={() => <Redirect to={process.env.UI_DEFAULT_ROUTE} />}
      />
    )

  const single_view_mode =
    (route_matching_module && !!route_matching_module.isFullscreen) ||
    process.env.UI_FULLSCREENVIEWMODE !== '0'

  return (
    <div
      className={classnames('App', {
        'single-view-mode': single_view_mode
      })}
    >
      {/* In single_view_mode the branding is imported here to load the css. Be sure to return null in the branding js module to not render anything */}
      {single_view_mode && (
        <AsyncComponent
          moduleProvider={branding_module}
          componentSelector={mod => mod.Branding}
          render={false}
        />
      )}
      {!single_view_mode && (
        <Nav modules={modules} branding_module={branding_module} />
      )}
      <Switch>
        {view_routes}
        {default_route}
        <Route component={ViewNotFoundIndicator} />
      </Switch>
    </div>
  )
}

function App (props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: () => {
          // do nothing.
        }
      }
    }
  })

  return (
    <Provider store={store}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <QueryClientProvider client={queryClient}>
            <AppContent {...props} />
          </QueryClientProvider>
        </QueryParamProvider>
      </Router>
    </Provider>
  )
}
export default App
