import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import classnames from 'classnames'

import './CircularProgress.scss'

export default function CircularProgress ({
  progress = -1, // negative -> indeterminate (just spinning)
  radius = 15,
  stroke = 2,
  className = '',
  sentiment = 'positive'
}) {
  const internal_progress =
    progress < 0 ? 0.2 : Math.min(1, Math.max(0, progress)) // "length" of the spinner
  const normalized_radius = radius - stroke * 2
  const circumference = normalized_radius * 2 * Math.PI
  const strokeDashoffset = circumference - internal_progress * circumference

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      className={classnames('CircularProgress', className, sentiment, {
        indeterminate: progress < 0
      })}
    >
      <circle
        className='background'
        fill='transparent'
        strokeWidth={stroke}
        r={normalized_radius}
        cx={radius}
        cy={radius}
      />
      <circle
        className='foreground'
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalized_radius}
        cx={radius}
        cy={radius}
      />
    </svg>
  )
}
