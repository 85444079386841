import React, { useState } from 'react'
import NavItem from '@base_ui/components/NavItem'
import AsyncComponent from '@base_ui/components/AsyncComponent'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import './Nav.scss'

export default function Nav ({ modules, branding_module }) {
  const disabled = useSelector(state => state.nav_reducer?.disabled)
  const [collapsed, set_collapsed] = useState(false)
  const links = modules.map(mod => {
    const Component = mod.hasOwnProperty('nav_item') ? mod.nav_item : NavItem
    return (
      <Component
        key={mod.route}
        exact={!!mod.route_exact}
        route={mod.route}
        linkTitle={mod.linkTitle}
        disabled={disabled?.includes(mod.linkTitle)}
        linkIcon={mod.linkIcon}
        required_scopes={mod.required_scopes}
        allowed_user_ids={mod.allowed_user_ids}
        collapsed={collapsed}
      />
    )
  })
  return (
    <div className={classnames({ collapsed }, 'Nav')}>
      {branding_module && (
        <AsyncComponent
          moduleProvider={branding_module}
          componentSelector={mod => mod.Branding}
        />
      )}
      <div className='flex-grow-shrink' />
      {links}
      <div className='divider' />
      <button
        type='button'
        className='NavItem'
        onClick={() => set_collapsed(!collapsed)}
        title={collapsed ? 'Expand' : null}
      >
        <span className='material-icons collapse-icon'>chevron_left</span>
        <div className='title'>Collapse</div>
      </button>
    </div>
  )
}
