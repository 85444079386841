import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function useAuthentication () {
  return useSelector(state => state.hasOwnProperty('user'))
}

export function useUser () {
  return useSelector(state => state.user?.user)
}

export function check_scope (required_scope, user) {
  // * as scope allows EVERYTHING
  if (user.scope.includes('*')) return true

  // Convenience: Replace # with user id in the required scope
  required_scope = required_scope.replace('#', String(user.user_id))

  // Exact match
  if (user.scope.includes(required_scope)) return true

  const required_scope_segments = required_scope.split('.')

  outer: for (let scope of user.scope) {
    // Check if any of the special characters is in the scope before trying harder
    if (scope.includes('*') || scope.includes('|')) {
      const scope_segments = scope.split('.').map(s => s.split('|'))

      // Longer scope than required scope can't work
      // Shorter scope than required scope only works if it ends with a '*'
      if (
        scope_segments.length > required_scope_segments.length ||
        (scope_segments.length < required_scope_segments.length &&
          !scope.endsWith('*'))
      )
        continue

      // Check segment by segment
      for (const [i, scope_segment] of scope_segments.entries()) {
        const required_scope_segment = required_scope_segments[i] // eslint-disable-line security/detect-object-injection

        if (!scope_segment.some(s => s === '*' || s === required_scope_segment))
          continue outer
      }
      // --> The for loop didn't break. This means that every segment in the
      //     scope matched with the required segments and/or the provided scope ends with '*'
      return true
    }
  }
  return false
}

export function authorize_scope (
  required_scopes = [],
  user,
  auth_enabled = true
) {
  if (!auth_enabled || !required_scopes || required_scopes.length < 1) {
    return true
  } else if (user && user.scope && user.scope.length > 0) {
    return required_scopes.every(scope => check_scope(scope, user))
  }
  return false
}

export function authorize_user_id (
  allowed_user_ids = [],
  user,
  auth_enabled = true
) {
  if (!auth_enabled || !allowed_user_ids || allowed_user_ids.length < 1) {
    return true
  } else if (user && user.user_id) {
    if (allowed_user_ids.includes('*')) return true // Simple way to authorize all logged in users
    return allowed_user_ids.includes(user.user_id)
  }
  return false
}

export function authorize ({
  required_scopes = [],
  allowed_user_ids = [],
  user,
  auth_enabled = true
}) {
  return (
    authorize_scope(required_scopes, user, auth_enabled) &&
    authorize_user_id(allowed_user_ids, user, auth_enabled)
  )
}

export function useAuthorize ({ required_scopes = [], allowed_user_ids = [] }) {
  const user = useUser()
  const auth_enabled = useAuthentication()
  return useMemo(
    () =>
      authorize({
        required_scopes,
        allowed_user_ids,
        user,
        auth_enabled
      }),
    [required_scopes, allowed_user_ids, user, auth_enabled]
  )
}

export function useAuthorizeScope (required_scopes) {
  return useAuthorize({ required_scopes })
}

export function useAuthorizeUserId (allowed_user_ids) {
  return useAuthorize({ allowed_user_ids })
}
