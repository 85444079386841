import React, { cloneElement } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@common/components/ui_elements/Button'
import { useUser } from '@base_ui/hooks/auth'
import {
  useAuthorize,
  useAuthorizeScope,
  useAuthorizeUserId
} from '@base_ui/hooks/auth'

export const NotLoggedInError = withRouter(({ history, error_children }) => {
  return (
    <>
      {error_children}
      <Button onClick={() => history.push('/login')} className='login-button'>
        Login
      </Button>
    </>
  )
})

export function BaseAuthorizeGate ({
  children,
  permission_granted,
  error_component = null,
  show_login_button = false,
  error_props = null
}) {
  const user = useUser()

  if (permission_granted) return children

  if (error_props) return cloneElement(children, { ...error_props })

  if (show_login_button && !user)
    return NotLoggedInError({
      error_children: error_component && error_component()
    })
  return error_component && error_component()
}

export function Authorize ({
  required_scopes = [],
  allowed_user_ids = [],
  ...props
}) {
  const permission_granted = useAuthorize({
    required_scopes,
    allowed_user_ids
  })
  return (
    <BaseAuthorizeGate permission_granted={permission_granted} {...props} />
  )
}

export function AuthorizeScope ({ required_scopes = [], ...props }) {
  const permission_granted = useAuthorizeScope(required_scopes)
  return (
    <BaseAuthorizeGate permission_granted={permission_granted} {...props} />
  )
}

export function AuthorizeUserId ({ allowed_user_ids = [], ...props }) {
  const permission_granted = useAuthorizeUserId(allowed_user_ids)
  return (
    <BaseAuthorizeGate permission_granted={permission_granted} {...props} />
  )
}
