// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../framework/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../framework/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircularProgress > .background {
  stroke: var(--colorLightGray);
}
@keyframes rotating {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(270deg);
  }
}
.CircularProgress > .foreground {
  transition: stroke-dashoffset 0.2s ease-in-out;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.CircularProgress.indeterminate > .foreground {
  animation: rotating 1s linear infinite;
}
.CircularProgress.positive > .foreground {
  stroke: var(--buttonPositiveColor);
}
.CircularProgress.neutral > .foreground {
  stroke: var(--buttonNeutralColor);
}
.CircularProgress.negative > .foreground {
  stroke: var(--buttonNegativeColor);
}
.CircularProgress.waiting > .foreground {
  stroke: var(--buttonWaitingColor);
}`, "",{"version":3,"sources":["webpack://./../common/src/components/ui_elements/CircularProgress.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;AAAJ;AAGE;EACE;IACE,yBAAA;EADJ;EAGE;IACE,yBAAA;EADJ;AACF;AAIE;EACE,8CAAA;EACA,yBAAA;EACA,yBAAA;AAFJ;AAKE;EACE,sCAAA;AAHJ;AAME;EACE,kCAAA;AAJJ;AAOE;EACE,iCAAA;AALJ;AAQE;EACE,kCAAA;AANJ;AASE;EACE,iCAAA;AAPJ","sourcesContent":[".CircularProgress {\n  > .background {\n    stroke: var(--colorLightGray);\n  }\n\n  @keyframes rotating {\n    from {\n      transform: rotate(-90deg);\n    }\n    to {\n      transform: rotate(270deg);\n    }\n  }\n\n  > .foreground {\n    transition: stroke-dashoffset 0.2s ease-in-out;\n    transform: rotate(-90deg);\n    transform-origin: 50% 50%;\n  }\n\n  &.indeterminate > .foreground {\n    animation: rotating 1s linear infinite;\n  }\n\n  &.positive > .foreground {\n    stroke: var(--buttonPositiveColor);\n  }\n\n  &.neutral > .foreground {\n    stroke: var(--buttonNeutralColor);\n  }\n\n  &.negative > .foreground {\n    stroke: var(--buttonNegativeColor);\n  }\n\n  &.waiting > .foreground {\n    stroke: var(--buttonWaitingColor);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
