import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import useModal from '@gestalt/common/src/hooks/useModal'
import './Button.scss'

export default function Button ({
  type = 'primary',
  sentiment = 'positive',
  disabled = false,
  progress_percentage = null,
  progress_bar_percentage = null,
  onClick = null,
  onError = null,
  onErrorConfirmed = null,
  show_modal_on_exception = true,
  className = '',
  small = false,
  medium = false,
  color = 'red',
  testid = null,
  children,
  ...additional_props
}) {
  const [show_modal, hide_modal, RenderModal] = useModal()
  const [pressed, set_pressed] = useState(false)
  const [error, set_error] = useState(null)
  const [async_in_progress, set_async_in_progress] = useState(false)
  const is_unmounted_ref = useRef(false)

  useEffect(() => {
    return () => (is_unmounted_ref.current = true)
  }, [])

  const btn_class_names = useMemo(
    () =>
      classnames([
        {
          primary: type === 'primary',
          secondary: type === 'secondary',
          positive: sentiment === 'positive',
          negative: sentiment === 'negative',
          neutral: sentiment === 'neutral',
          waiting: sentiment === 'waiting',
          'with-progress': progress_percentage !== null,
          'with-progress-bar': progress_bar_percentage !== null,
          disabled: disabled || async_in_progress,
          pressed,
          small,
          medium
        }
      ]),
    [
      type,
      sentiment,
      progress_percentage,
      disabled,
      async_in_progress,
      pressed,
      small,
      medium,
      progress_bar_percentage
    ]
  )

  const effectively_disabled = useMemo(() => disabled || async_in_progress, [
    disabled,
    async_in_progress
  ])

  const on_button_click = useCallback(async () => {
    if (!effectively_disabled && onClick !== null) {
      set_async_in_progress(true)
      try {
        await onClick()
      } catch (err) {
        set_error(err)
        show_modal()
        if (onError !== null) {
          onError(err)
        }
      }
      if (!is_unmounted_ref.current) {
        set_async_in_progress(false)
      }
    }
  }, [
    onClick,
    set_error,
    show_modal,
    onError,
    is_unmounted_ref,
    set_async_in_progress,
    effectively_disabled
  ])

  const error_modal_on_close_button = useCallback(() => {
    hide_modal()
    // set_async_in_progress(false)
    onErrorConfirmed && onErrorConfirmed(error)
  }, [error, onErrorConfirmed, hide_modal])

  return (
    <div
      className={classnames(
        'Button',
        {
          disabled: effectively_disabled
        },
        className
      )}
      {...additional_props}
    >
      <button
        className={btn_class_names}
        disabled={disabled || async_in_progress}
        onClick={on_button_click}
        onMouseDown={() => !effectively_disabled && set_pressed(true)}
        onMouseUp={() => set_pressed(false)}
        onMouseLeave={() => set_pressed(false)}
        data-testid={testid}
      >
        {progress_bar_percentage !== null && (
          <div
            className='progress-overlay'
            style={{ transform: `scaleX(${progress_bar_percentage / 100})` }}
          />
        )}
        <span className='btn-elements btn-text'>{children}</span>
        {progress_percentage !== null && (
          <span className='btn-elements btn-progress'>
            {progress_percentage}%
          </span>
        )}
      </button>
      {error && (
        <RenderModal sentiment='negative'>
          {error &&
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.exception ? (
            <>
              <header>
                <header>Backend Error</header>
                <footer>{error.response.data.error.exception.type}</footer>
              </header>
              <main>{error.response.data.error.exception.text}</main>
            </>
          ) : (
            <>
              <header>
                <header>Error</header>
              </header>
              <main>
                <pre>{error.stack}</pre>
              </main>
            </>
          )}
          <footer>
            <Button
              type='secondary'
              sentiment='neutral'
              onClick={error_modal_on_close_button}
            >
              Close
            </Button>
          </footer>
        </RenderModal>
      )}
    </div>
  )
}
