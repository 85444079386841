import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// export default function configureStore () {
//   return createStore(rootReducer, storeEnhancers(applyMiddleware(thunk)))
// }

export default (() => {
  const store = createStore(
    createReducer(),
    storeEnhancers(applyMiddleware(thunk))
  )
  store.async = {}

  return store
})()

export function registerReducer (store, name, reducer) {
  // The module names are hardcoded and thus static after build
  // eslint-disable-next-line security/detect-object-injection
  store.async[name] = reducer
  store.replaceReducer(createReducer(store.async))
}

function createReducer (reducers) {
  const app_reducer = combineReducers({
    /* Obs.:
     All non dynamic loaded reducers are declared directly here.
     If you don't have any, use the following "root" indentity reducer.
    */
    void: (state = null) => state,
    ...reducers
  })

  return (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return app_reducer(undefined, action)
    }

    return app_reducer(state, action)
  }
}
